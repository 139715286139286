import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SubjectObj } from '../../shared/exam-selector/subject-obj';
import { ListerService } from '../../chat/lister.service';
import { ClientInfoService } from '../../shared/client-info.service';

@Component({
  selector: 'app-just-subject-list',
  templateUrl: './just-subject-list.component.html',
  styleUrls: ['./just-subject-list.component.scss']
})
export class JustSubjectListComponent implements OnInit {
  sl$: Observable<any>;
  clsSec$: Observable<any>;
  clsSecCall: boolean;
  otherCall: boolean;
  show: boolean = true;
  
  
get site() {return  this.cs.getSite()} ;
get cat() {return this.cs.getCat() };
get board() {return this.cs.getBoard()};
get course() { return this.cs.getCourse()};
get feature() { return this.cs.getFeature()};
get offering() { return this.cs.getOffering()};
get justOffering() { return this.cs.getJustOffering()};
get subject() { return this.cs.getSubject()};

get signedUser() { return this.cs.getSignedUser()};
get betaViewer() { return this.cs.betaViewer()}

get showAssignedSub(){ return this.cs.enable('examSlctr','showAssignedSub')}
get showAllSubForCreate(){ return this.cs.enable('examSlctr','showAllSubCreate')}
  

  constructor(public cs:ClientInfoService,public ls:ListerService) { }


  ngOnInit(): void {
    
   //"hwh","rbs","pyq","pbstr","vid","doc"
   if( (this.site.includes("ebc") || ["KCET","NEET","JEE","UPSC","GRE","MBBS_RGHUS","IT"].includes(this.board) ) && !(["nws","rvw","car"].includes(this.justOffering) ))
    {
     this.clsSecCall= true; 
     this.clsSec$ = this.ls.getClsSecInfo("cmn");  
    } else{ 
      this.otherCall= true;
      let ovrType= this.offering;
     if(this.offering =="unit"){
      if(!this.site.includes('csa')){
       ovrType= "hwh" 
      }
     } 
     this.sl$ = this.ls.getOnlySubjectList(ovrType) 
   }
  

  

  }

  loadSubject(){
    this.otherCall= true;
    this.sl$ = this.ls.getOnlySubjectList();
     }


showSub(sub:SubjectObj){
  if(sub.status && sub.status=="beta" && !this.betaViewer){return false}

  return this.showAllSubForCreate || !this.showAssignedSub || sub.teacher == this.signedUser ;
 
 }
 
  getRoute(s:string){
    
    if(this.feature && !this.offering){
      s= "type/" + s;
    }
    
  let url = this.cs.router.url + "/" + s;
   return url; 
  }


}
